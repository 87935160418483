import { AuthPanel } from '@/components/auth';
import { Flex } from '@/ui';

export const QuickCreateAuth = () => {
  return (
    <Flex p="8">
      <AuthPanel
        fullWidth
        redirectTo="/magic-auth/success"
        onAuthenticated={() => window.close()}
      />
    </Flex>
  );
};
